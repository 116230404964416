<!--
 * @Author: 苏航
 * @Date: 2021-05-25 08:35:14
 * @LastEditors: 苏航
 * @LastEditTime: 2021-07-26 12:09:03
 * @version: V.x
 * @Descripttion: 说明...
-->
<template>
  <div class="content">
    <div class="main">
      <service-and-support-nav />
      <contact-us />
    </div>
  </div>
</template>

<script>
import ServiceAndSupportNav from "@/components/service-and-support/nav";
import contactUs from "@/components/contact-us";
export default {
  name: "ServiceAndSupport",
  components: {
    ServiceAndSupportNav,
    contactUs,
  },
  data() {
    return {};
  },
  mounted() {
    // this.getData();
  },
  methods: {
    getData() {
      this.$http.parts().then((res) => {
        this.parts = res.data.parts;
        this.spare = res.data.spare;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.main {
  width: 1400px;
  margin: 0 auto;
  @include respond-to(max1439) {
    width: 95%;
  }
  @include respond-to(lg) {
    width: 100%;
    padding: 15px;
  }
}
</style>